import './App.css';
import { useMemo, useRef, useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme'
import { Stack, Box, Typography, Button, Link, AppBar, Toolbar, Drawer, List, ListItem, ListItemText, ListItemButton, IconButton, useMediaQuery } from '@mui/material';
import Faq from "react-faq-component";
import reibb from './images/rei-bb-logo-white.png';
import recruiter from './images/recruiter.png';
import dd from './images/Don-Dodds.png';
import evaltour from './images/evaltour.png';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import SecurityUpdateGoodOutlinedIcon from '@mui/icons-material/SecurityUpdateGoodOutlined';
import FitScreenOutlinedIcon from '@mui/icons-material/FitScreenOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import MenuIcon from '@mui/icons-material/Menu';

const publishable_key = process.env.REACT_APP_STRIPE_PK;
const pt1 = process.env.REACT_APP_STRIPE_TABLE_ID_1;
const pt2 = process.env.REACT_APP_STRIPE_TABLE_ID_2;

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);
  const mobile = useMediaQuery('(max-width:800px)');
  const tablet = useMediaQuery('(max-width:1300px)');
  const [clientReferenceId, setClientReferenceId] = useState(null);

  const imageSources = [
    reibb,
    dd,
    recruiter,
    evaltour,
    `${process.env.PUBLIC_URL}/ioaudio.png`,
    `${process.env.PUBLIC_URL}/secomind.png`
  ];

  const hoverStyle = {
    transition: 'transform 0.4s', // Adjust the duration as needed
    '&:hover': {
      transform: mobile ? 'scale(1.02)' : 'scale(1.07)', // Adjust the scale factor as desired
      boxShadow: '0 0 40px rgba(0, 0, 0, 0.7)', // Add the drop shadow effect
    } 
  };

  function getClientReferenceId() {
    return window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime());
  }

  useEffect(() => {
    
    // call your function here
    const referenceId = getClientReferenceId();
    window.gr('track', 'conversion', { email: `${referenceId}@example.com` });

    // set the result to state
    setClientReferenceId(referenceId);
  }, []); // empty dependency array ensures this runs only on initial render


  const data = {
    rows: [
        {
            title: <span style={{fontSize: 20}}><b>Why wouldn't I just hire a full-time software engineer?</b></span>,
            content: `Good question! For starters, the annual cost of a full-time senior-level designer now exceeds $250,000, plus benefits (and good luck finding a team of them that can specialize in generative AI integration). Aside from that, you may not always have enough work to keep them busy at all times, so you're stuck paying for time you aren't able to utilize.
            <br/><br/>
            With the monthly plan, you can pause and resume your subscription as often as you need to ensure you're only paying your developer when you have work available for them.`,
        },
        {
            title: <span style={{fontSize: 20}}><b>Is there a limit to how many requests I can have?</b></span>,
            content:
                "Once subscribed, you're able to add as many design requests to your queue as you'd like, and they will be delivered one by one.",
        },
        {
          title: <span style={{fontSize: 20}}><b>How fast will I receive my designs?</b></span>,
          content: `The speed of delivery will depend on your plan. The Starter plan is limited to 1 request at a time, and the Pro plan is limited to 2 requests at a time. The Enterprise plan is limited to 3 requests at a time.`,
        },
        {
          title: <span style={{fontSize: 20}}><b>Who are the developers?</b></span>,
          content: `We're a lean agency of handful of software engineers. Alvin, our Founder and CEO, has founded 2 other startups in the AI space, and has trained the team to deliver high quality work in a streamlined manner.`,
        },
        {
          title: <span style={{fontSize: 20}}><b>What is your tech stack?</b></span>,
          content: `We typically build our apps using the MERN stack (MongoDB, Express, React, Node.js). If you choose the mobile development add on, then we will switch out React with React Native.<br/>We also use a variety of other tools and technologies, including but not limited to: Azure, GCP, OpenAI, Stable Diffusion, and more.`,
        },
        {
          title: <span style={{fontSize: 20}}><b>What kind of AI do you specialize in?</b></span>,
          content: `We are experts in incorporating Generative AI into SaaS apps. This includes text generation, image generation, speech to text, and text to speech. We also have experience with computer vision, NLP, and more.`,
        },
        {
          title: <span style={{fontSize: 20}}><b>How do I request features?</b></span>,
          content: `We offer a ton of flexibility in how you request designs using Trello. Some common ways clients request features is directly via Trello, sharing Google docs or wireframes, or even recording a brief Loom video (for those who prefer not to write their briefs out). Basically, if it can be linked to or shared in Trello, it's fair game.<br/><br/>
          However before getting access to Trello, you will be required to submit a Project Scope document, which will help us understand your project and ensure we're a good fit for your needs. To submit a Project Scope, please begin a free trial on the plan of your choice.`,
        },
        {
          title: <span style={{fontSize: 20}}><b>What if I only want an initial prototype or MVP?</b></span>,
          content: `That's perfectly okay! You can pause your subscription once your prototype or MVP is finished and return when need additional dev work. There's no need to let the rest of your subscription go to waste.`,
        },
        {
            title: <span style={{fontSize: 20}}><b>Are there any refunds if I don't like the service?</b></span>,
            content: `Due to the high quality and intense nature of the work, we will not issue refunds.`,
        },
    ],
  };

  const styles = {
      // bgColor: 'white',
      bgColor: `transparent`,
      titleTextColor: "black",
      rowTitleColor: "black",
      // rowContentColor: 'grey',
      // arrowColor: "red",
  };

  const config = {
      // animate: true,
      // arrowIcon: "V",
      // tabFocus: true
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const [activeSection, setActiveSection] = useState(null);


  useEffect(() => {
    const sections = [
      { name: 'section1', ref: ref1 },
      { name: 'section2', ref: ref2 },
      { name: 'section3', ref: ref3 },
      { name: 'section4', ref: ref4 },
      { name: 'section5', ref: ref5 },
      { name: 'section6', ref: ref6 },
    ];
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset + window.innerHeight * 0.4;
      sections.forEach(section => {
        if (section.ref.current && scrollPosition >= section.ref.current.offsetTop) {
          setActiveSection(section.name);
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToRef = (ref) => window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth'
  });

  const isActive = (name) => name === activeSection;

  const DrawerComponent = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
        <Drawer anchor = 'right' onClose = {() => setOpenDrawer(false)} open = {openDrawer} >
            <List>
              <ListItem key={"Benefits"} disablePadding>
                  <ListItemButton onClick={()=> scrollToRef(ref2)}>
                      <ListItemText primary={"Benefits"} />
                  </ListItemButton>
              </ListItem>
              
              <ListItem key={"Recent Work"} disablePadding>
                  <ListItemButton onClick={()=> scrollToRef(ref3)}>
                      <ListItemText primary={"Recent Work"} />
                  </ListItemButton>
              </ListItem>

              <ListItem key={"Pricing"} disablePadding>
                <ListItemButton onClick={()=> scrollToRef(ref4)}>
                    <ListItemText primary={"Pricing"} />
                </ListItemButton>
              </ListItem>

              <ListItem key={"FAQs"} disablePadding>
                <ListItemButton onClick={()=> scrollToRef(ref5)}>
                    <ListItemText primary={"FAQs"} />
                </ListItemButton>
              </ListItem>

              <ListItem divider key={"Upload Scope Doc"} disablePadding>
                <ListItemButton onClick={()=> window.open("https://forms.gle/vAoFK1LyMbvWyZV68", '_blank')}>
                    <ListItemText primary={"Upload Scope Doc"} />
                </ListItemButton>
              </ListItem>

              <ListItem key={"Login"} disablePadding>
                <ListItemButton onClick={()=> window.open("https://billing.stripe.com/p/login/5kAg0A6fh86NbjG288", "_blank")}>
                    <ListItemText primary={"Login"} />
                </ListItemButton>
              </ListItem>
            </List>
        </Drawer>
        <IconButton onClick = {() => setOpenDrawer(!openDrawer)} sx={{ml:'auto'}}>
            <MenuIcon sx={{color:'inherit', fontSize:'30px'}}/>
        </IconButton>
        </>
    )
  }
  
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box position="relative">
        <Box className="App" py={2} px={mobile ? 2 : 0} sx={{backgroundImage: `url(https://i.imgur.com/c0UhPfv.jpg)`, position: "relative"}} ref={ref1}>
          
          {!mobile && <>
          <Typography variant="h3" fontWeight="bold" fontFamily="Caveat">innov<span style={{color: "red"}}>AI</span>te</Typography>
          <img src={`${process.env.PUBLIC_URL}/draw2.svg`} height={tablet ? "200px" : "300px"} width={tablet ? "200px" : "300px"} alt="doodle 1" style={{position: "absolute", left: "-50px", top: "17vh", zIndex: 2}}/>
          <img src={`${process.env.PUBLIC_URL}/doodle2.svg`} height={tablet ? "200px" : "300px"} width={tablet ? "200px" : "300px"} alt="doodle 2" style={{position: "absolute", left: "-110px", top: "18vh", zIndex: 1}}/>
          <img src={`${process.env.PUBLIC_URL}/stars.svg`} height={tablet ? "70px" : "150px"}width={tablet ? "70px" : "150px"}alt="doodle 3" style={{position: "absolute", right: "12%", top: "17vh", zIndex: 2}}/>
          <img src={`${process.env.PUBLIC_URL}/draw1.svg`} height={tablet ? "180px" : "250px"}width={tablet ? "180px" : "250px"}alt="doodle 4" style={{position: "absolute", right: "-60px", top: "23vh", zIndex: 1}}/>
          </>}
          <Typography variant={mobile ? "h3" : "h1"} fontWeight="bold" mt={mobile ? 10 : 14}>AI-powered SaaS<br/> <i>Built Fast</i></Typography>
          <Typography fontSize={mobile ? 20 : 24} mt={4} mb={5}>Subscriptions to build an amazing SaaS</Typography>
          <Button variant="contained" onClick={() => scrollToRef(ref4)}
          sx={{fontSize: mobile ? 16 : 18, textTransform: "none", fontWeight: "bold", px: 7, py: 2, borderRadius: 2, transition: 'transform 0.4s', 
          '&:hover': {
            bgcolor: "#000",
            transform: 'scale(1.07)', // Adjust the scale factor as desired
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.7)', // Add the drop shadow effect
          } 
          }}>
            View Plans
          </Button>
          <Typography mt={3}>Web apps you'll love, guaranteed</Typography>

           {/*website cards*/}
           {mobile ? <Box width="100%" mt={8} mb={5} display="flex" justifyContent="center" >
            <Box sx={hoverStyle}  height="240px" width="auto" onClick={() => scrollToRef(ref3)}>
              <img src={`${process.env.PUBLIC_URL}/10kprev.webp`} alt="tenkdecoder" height="240px" width="auto" style={hoverStyle}/>
            </Box>
           </Box> :
          <Stack direction="row" width="100%" spacing={6} mt={13} mb={10}>
            <Box sx={hoverStyle} height="400px" width="auto" onClick={() => scrollToRef(ref3)}>
              <img src={`${process.env.PUBLIC_URL}/piearm.webp`} alt="piearm" height="400px" width="auto" style={hoverStyle}/>
            </Box>
            <Box sx={hoverStyle}  height="400px" width="auto" onClick={() => scrollToRef(ref3)}>
              <img src={`${process.env.PUBLIC_URL}/cp.webp`} alt="cp" height="400px" width="auto" style={hoverStyle}/>
            </Box>
            <Box sx={hoverStyle} height="400px" width="auto" onClick={() => scrollToRef(ref3)}>
              <img src={`${process.env.PUBLIC_URL}/10kprev.webp`} alt="tenkdecoder" height="400px" width="auto" style={hoverStyle}/>
            </Box>
            <Box sx={hoverStyle}  height="400px" width="auto" onClick={() => scrollToRef(ref3)}>
              <img src={`${process.env.PUBLIC_URL}/cp.webp`} alt="cp" height="400px" width="auto" style={hoverStyle}/>
            </Box>
            <Box sx={hoverStyle} height="400px" width="auto" onClick={() => scrollToRef(ref3)}>
              <img src={`${process.env.PUBLIC_URL}/piearm.webp`} alt="piearm" height="400px" width="auto" style={hoverStyle}/>
            </Box>
          </Stack>
          }
        </Box>

        {/*carousel*/}
        <Box className="image-container" sx={{ backgroundColor: 'black', overflow: 'hidden', maxHeight: '300px', width: '100%'}}>
        <div className="image-slider">
          {imageSources.map((src, index) => (
            <Box mx={1} mt={1}>
              <img className="carousel-image" key={`img1-${index}`} src={src} alt={`img1-${index}`} />
            </Box>
          ))}
          {imageSources.map((src, index) => (
            <Box mx={1} mt={1}>
              <img className="carousel-image" key={`img2-${index}`} src={src} alt={`img2-${index}`} />
            </Box>
          ))}
        </div>
    </Box>
        
        <Stack className="App" sx={{backgroundImage: `url(https://i.imgur.com/kZjP0Jh.jpg)`, position: "relative", zIndex: 0}} pt={mobile ? 8 : 12} pb={mobile ? 13 : 20} alignItems="center"
        //https://i.imgur.com/H4Y1HDG.jpg
        //https://i.imgur.com/k9oOh9y.jpg
        //https://i.imgur.com/s8sLiCR.jpg
        >
          <Typography variant={mobile ? "h4" : "h2"} fontWeight="bold" > Quality <span style={{ textDecoration: 'line-through' }}>over</span> and Quantity</Typography>
          <Typography fontSize={mobile ? 18 : 24} mt={4}>Exceptional SaaS apps delivered<br/>in record time.</Typography>
          <Stack direction={mobile ? "column" : "row"} spacing={mobile ? 7 : 13} mt={mobile ? 7 : 13} mb={mobile ? 5 : 10} alignItems="flex-start" justifyContent="center">
            <Box >
              <img src={process.env.PUBLIC_URL + '/subscribe.svg'} alt="description of animation" height="80px" width="80px"/>
              <Typography fontSize={mobile ? 16 : 20} mt={2}>Subscribe to a plan & continuously<br/> request features.</Typography>
            </Box>
            <Box>
              <img src={process.env.PUBLIC_URL + '/heart.svg'} alt="description of animation" height="80px" width="80px"/>
              <Typography fontSize={mobile ? 16 : 20} mt={2}>Receive a fully functional prototype<br/> or MVP in under a month.</Typography>
            </Box>
            <Box>
              <img src={process.env.PUBLIC_URL + '/rotates.svg'} alt="description of animation" height="80px" width="80px"/>
              <Typography fontSize={mobile ? 16 : 20} mt={2}>We'll add new features and revisions <br/> until you're 100% satisfied.</Typography>
            </Box>
          </Stack>
          <Box sx={{backgroundImage: `url(https://i.imgur.com/c0UhPfv.jpg)`,  position: "relative"}} width="70vw" >
            <img src={`${process.env.PUBLIC_URL}/doodle1.svg`} height={mobile ? "100px" : "150px"} width={mobile ? "100px" : "150px"} alt="doodle 5" style={{position: "absolute", right: "-40px", top: "-20px", zIndex: 1}}/>
            <Typography mt={11} fontSize={mobile ? 75 : 100} fontWeight ="bold" fontFamily="Caveat" color="orangered" lineHeight={0}>“</Typography>
            <Typography variant={mobile ? "h5" : "h4"} fontWeight ="bold" mb={6}>Superior work!<br/>Brilliant!<br/>Phenomenal!</Typography>
              <img src={`${process.env.PUBLIC_URL}/draw3.svg`} height={mobile ? "75px" : "150px"} width={mobile ? "75px" : "150px"}alt="doodle 5" style={{position: "absolute", left: "-40px", bottom: "-20px", zIndex: 1}}/>
            </Box>
        </Stack>

        <Stack sx={{backgroundImage: `url(https://i.imgur.com/tsOxIOo.jpg)`, position: "relative", overflowX: "hidden"}} px={mobile ? 1 : 0} pt={mobile ? 8 : 12} pb={mobile ? 10 : 20} alignItems="center">
          {mobile ? <Typography variant="h4" fontWeight="bold" textAlign="center">Find the finest, lose the rest.</Typography> :
          <Typography variant="h2" fontWeight="bold" textAlign="center">Find the finest,<br/>lose the rest.</Typography>}
          <Typography fontSize={mobile ? 18 : 24} mt={4} textAlign="center">
            <span style={{fontFamily: "Caveat", fontSize: 30, fontWeight: "bold"}}>innov<span style={{color: "red"}}>AI</span>te</span>&nbsp;&nbsp;
            replaces unreliable freelancers and expensive<br/>
          agencies for one flat monthly fee, with iterations delivered so<br/>
          fast that it will blow your mind.</Typography>
          <Stack direction={mobile ? "column" : "row"} spacing={6} alignItems="flex-center" justifyContent="center" mt={12} >
            <Stack height="340px" sx={{backgroundImage: `url(https://i.imgur.com/kZjP0Jh.jpg)`}} width={mobile ? "94%" : "35vw"} p={4} pt={12} alignItems="center" justifyContent="center">
              <Typography fontSize={mobile ? 75 : 100} fontWeight ="bold" fontFamily="Caveat" color="orangered" lineHeight={0}>“</Typography>
              <Typography variant={mobile ? "h6" : "h5"} fontWeight="bold">Exceeded my expectations in terms of prompt communication and timely delivery of milestones.</Typography>
              <Box width="100%">
                <Stack direction = "row" alignItems={mobile ? "flex-start" : "center"} mt={mobile ? 1 : 2} spacing={1}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Meta_Platforms_Inc._logo.svg/2560px-Meta_Platforms_Inc._logo.svg.png" 
                alt="meta" height={mobile ? "11px" : "14px"} width="auto" style={{marginTop: "5px"}}/>
                <Typography fontSize={mobile ? 14 : 18} textAlign="left">Head of Partner Products and Commerce</Typography>
                </Stack>
              </Box>
            </Stack>
            <Box height="340px" width={mobile ? "80vw" : "33vw"}>
              <Box width={mobile ? "80vw" : "30vw"}>
                <Typography fontSize={mobile ? 14 : 18} fontWeight="bold">Flexible Commmunication</Typography>
                <Typography fontSize={mobile ? 14 : 18} mt={1}>Don't like meetings? Fine with us! Want weekly updates? That works too!</Typography>

                <Typography fontSize={mobile ? 14 : 18} fontWeight="bold" mt={4}>Easy Request Management</Typography>
                <Typography fontSize={mobile ? 14 : 18} mt={1}>Manage your requests using Trello. View current, queued and finished tasks any time.</Typography>

                <Typography fontSize={mobile ? 14 : 18} fontWeight="bold" mt={4}>Invite your team</Typography>
                <Typography fontSize={mobile ? 14 : 18} mt={1}>Bring your whole team on board, allowing everyone to send requests and monitor their advancements.</Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
        

        <Stack sx={{backgroundImage: `url(https://i.imgur.com/kZjP0Jh.jpg)`}} pt={mobile ? 8 : 16} pb={mobile ? 10 : 20} alignItems="center" ref={ref2}>
          <img src={`${process.env.PUBLIC_URL}/crown.svg`} alt="logo" height="80px" width="80px" />
          <Typography variant={mobile ? "h4" : "h2"} fontWeight="bold" textAlign="center">Membership benefits</Typography>
          <Typography fontSize={mobile ? 18 : 24} my={4} textAlign="center">Perks so 🔥 you'll never think twice.<br/> Seriously.</Typography>
          <Button variant="contained" onClick={() => scrollToRef(ref4)}
          sx={{fontSize: mobile ? 16 : 18, textTransform: "none", fontWeight: "bold", px: 7, py: 2, borderRadius: 2, transition: 'transform 0.4s', 
          '&:hover': {
            bgcolor: "#000",
            transform: 'scale(1.07)', // Adjust the scale factor as desired
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.7)', // Add the drop shadow effect
          } 
          }}>
            View Plans
          </Button>
          {tablet ? 
          <Stack width="80vw" spacing={mobile ? 6 : 8} alignItems="center" mt={10}>
            <Stack direction="row" alignItems="center" spacing={mobile ? 5 : 10}>
              <Stack spacing={2} alignItems="center" className="App" width={mobile ? 130 : 240}>
                <AllInclusiveOutlinedIcon sx={{fontSize: 90}}/>
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Unlimited requests</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Add as many design requests to your board as you'd like.</Typography>
              </Stack>

              <Stack spacing={2} alignItems="center" className="App" width={mobile ? 130 : 240} >
                <EventAvailableOutlinedIcon sx={{fontSize: 90}} />
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Crazy fast delivery</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Receive an MVP in just 30 days on average</Typography>
              </Stack>

            </Stack>

            <Stack direction="row" alignItems="center" spacing={mobile ? 5 : 10}>

              <Stack spacing={2} alignItems="center" className="App" width={mobile ? 130 : 240}>
                <LockResetOutlinedIcon sx={{fontSize: 90}}/>
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Fixed monthly rate</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Keep it steady! Your monthly fee remains the same.</Typography>
              </Stack>
              <Stack spacing={2} alignItems="center" className="App" width={mobile ? 130 : 240}>
                <SecurityUpdateGoodOutlinedIcon sx={{fontSize: 90}}/>
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Top-notch quality</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Topnotch quality and security at your fingertips at all times.</Typography>
              </Stack>

            </Stack>

            <Stack direction="row" alignItems="center" spacing={mobile ? 5 : 10} >
              
              <Stack spacing={2} alignItems="center" className="App" width={mobile ? 130 : 240} >
                <FitScreenOutlinedIcon sx={{fontSize: 90}} />
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Flexible and scalable</Typography>
                <Typography fontSize={mobile ? 14 : 18}>Scale your plan up or down, and pause or cancel at anytime.</Typography>
              </Stack>
              <Stack spacing={2} alignItems="center" className="App" width={mobile ? 130 : 240}>
                <FingerprintOutlinedIcon sx={{fontSize: 90}}/>
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Unique and all yours</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Every SaaS app is made especially for you and is 100% yours.</Typography>
              </Stack>
            </Stack>
          </Stack>
          : 
          <Stack width="80vw" spacing={10} alignItems="center" mt={10}>
            <Stack direction="row" alignItems="center" spacing={18}>
              <Stack spacing={2} alignItems="center" className="App" width={300}>
                <AllInclusiveOutlinedIcon sx={{fontSize: 90}}/>
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Unlimited requests</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Add as many design requests to your board as you'd like.</Typography>
              </Stack>

              <Stack spacing={2} alignItems="center" className="App" width={300} >
                <EventAvailableOutlinedIcon sx={{fontSize: 90}} />
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Crazy fast delivery</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Receive an MVP in just 30 days on average</Typography>
              </Stack>

              <Stack spacing={2} alignItems="center" className="App" width={300}>
                <LockResetOutlinedIcon sx={{fontSize: 90}}/>
                <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Fixed monthly rate</Typography>
                <Typography fontSize={mobile ? 14 : 18} >Keep it steady! Your monthly fee remains the same.</Typography>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={18} alignItems="center">
              <Stack spacing={2} alignItems="center" className="App" width={300}>
                  <SecurityUpdateGoodOutlinedIcon sx={{fontSize: 90}}/>
                  <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Top-notch quality</Typography>
                  <Typography fontSize={mobile ? 14 : 18} >Topnotch quality and security at your fingertips at all times.</Typography>
                </Stack>

                <Stack spacing={2} alignItems="center" className="App" width={300} >
                  <FitScreenOutlinedIcon sx={{fontSize: 90}} />
                  <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Flexible and scalable</Typography>
                  <Typography fontSize={mobile ? 14 : 18}>Scale your plan up or down, and pause or cancel at anytime.</Typography>
                </Stack>

                <Stack spacing={2} alignItems="center" className="App" width={300}>
                  <FingerprintOutlinedIcon sx={{fontSize: 90}}/>
                  <Typography fontSize={mobile ? 16 : 20} fontWeight="bold">Unique and all yours</Typography>
                  <Typography fontSize={mobile ? 14 : 18} >Every SaaS app is made especially for you and is 100% yours.</Typography>
                </Stack>
            </Stack>
          </Stack>
        }
        </Stack>

        <Stack sx={{backgroundImage: `url(https://i.imgur.com/tsOxIOo.jpg)`, position: "relative", overflow: "hidden"}} 
        px={mobile ? 1 : 0} pt={mobile ? 8 : 12} pb={mobile ? 10 : 14} alignItems="center" ref={ref3}>
          <Typography variant={mobile ? "h4" : "h2"} fontWeight="bold" textAlign="center">Recent work</Typography>
          <Typography fontSize={mobile ? 18 : 24} my={mobile ? 3 : 4} textAlign="center">
            Revolutionary apps built in the past 6 months. <br/> And we're just getting started.
          </Typography>
          <Button variant="contained" onClick={() => window.open('https://alveiz.github.io/innovaite_portfolio/', '_blank')}
          sx={{fontSize: mobile ? 16 : 18, textTransform: "none", fontWeight: "bold", mb: 8, px: 7, py: 2, borderRadius: 2, transition: 'transform 0.4s', 
          '&:hover': {
            bgcolor: "#000",
            transform: 'scale(1.07)', // Adjust the scale factor as desired
            boxShadow: '0 0 40px rgba(0, 0, 0, 0.7)', // Add the drop shadow effect
          } 
          }}>
            View recent work
          </Button>
          <Box sx={hoverStyle}  width={mobile ? " 250px" : "380px"} height={mobile ? " 250px" : "380px"} onClick={() => window.open('https://alveiz.github.io/innovaite_portfolio/', '_blank')}>
            <img src={process.env.PUBLIC_URL + '/showcase.webp'} alt="showcase" width={mobile ? " 250px" : "380px"} height="auto" style={hoverStyle}/>
          </Box>
          {!mobile && <img src={process.env.PUBLIC_URL + '/draw4.svg'} height={tablet ? "180px" : "300px"} width={tablet ? "180px" : "300px"} alt="doodle xi" style={{position: "absolute", left: "-6%", top: "18%", zIndex: 1}}/>}
          {!mobile && <img src={process.env.PUBLIC_URL + '/draw1.svg'} height={tablet ? "180px" : "300px"} width={tablet ? "180px" : "300px"} alt="doodle x" style={{position: "absolute", right: "6%", top: "35%", zIndex: 1}}/>}
          {!mobile && <img src={process.env.PUBLIC_URL + '/draw5.svg'} height={tablet ? "110px" : "200px"} width={tablet ? "110px" : "200px"} alt="doodle xii" style={{position: "absolute", left: "11%", bottom: tablet ? "-50px":"-100px", zIndex: 1}}/>}
          {!mobile && <img src={process.env.PUBLIC_URL + '/draw6.svg'} height={tablet ? "85px" : "150px"} width={tablet ? "85px" : "150px"}  alt="doodle xiii" style={{position: "absolute", right: "18%", top: "77%", zIndex: 1}}/>}
        </Stack>

        <Box className="App" p={2} sx={{backgroundImage: `url(https://i.imgur.com/c0UhPfv.jpg)`, position: "relative"}} ref={ref4}>
          <Typography variant={mobile ? "h4" : "h2"} fontWeight="bold" mt={12}>Pricing plans</Typography>
          <Typography fontSize={mobile ? 18 : 24} my={4}>Choose the plan that best suits your needs.</Typography>
          <Box display="flex" alignItems="center" justifyContent="center" my={6}>
            <Typography fontSize={mobile ? 16 : 24} width={mobile ? "80%" : "50%"} textAlign="left">• Start your 7-day free trial and click the button below to submit your project scope along with any other documents you deem necessary.<br/>
            • We will review your uploads during your week trial<br/>
            • If we're not a match, no worries, we will automatically cancel your subscription before any charge is incurred.</Typography>
          </Box>
          <Stack direction={mobile ? "column": "row"} alignItems="center" justifyContent="center" spacing={4} mb={mobile ? 4 : 8}>
            <Button variant="contained" onClick={() => window.open('https://forms.gle/vAoFK1LyMbvWyZV68', '_blank')}
            sx={{fontSize: mobile ? 16 : 18, textTransform: "none", fontWeight: "bold", px: 7, py: 2, borderRadius: 2, transition: 'transform 0.4s', 
            '&:hover': {
              bgcolor: "#000",
              transform: 'scale(1.07)', // Adjust the scale factor as desired
              boxShadow: '0 0 40px rgba(0, 0, 0, 0.7)', // Add the drop shadow effect
            } 
            }}>
              Upload project scope
            </Button>
            <Button variant="contained" onClick={() => window.open('https://innovaite.trackdesk.com/sign-up', '_blank')}
            sx={{fontSize: mobile ? 16 : 18, textTransform: "none", fontWeight: "bold",px: 7, py: 2, borderRadius: 2, transition: 'transform 0.4s', 
            '&:hover': {
              bgcolor: "#000",
              transform: 'scale(1.07)', // Adjust the scale factor as desired
              boxShadow: '0 0 40px rgba(0, 0, 0, 0.7)', // Add the drop shadow effect
            } 
            }}>
              Become an affiliate
            </Button>
          </Stack>
          
          <Stack direction={mobile ? "column" : "row"} spacing={8} alignItems="center" justifyContent="center" mt={4} mb={mobile ? 5 : 10}>
            <Box width={mobile ? "90vw" : "60vw"} py={mobile ? 2 : 4}>
              <stripe-pricing-table pricing-table-id={pt1}
              publishable-key={publishable_key}
              client-reference-id={clientReferenceId}
              >
              </stripe-pricing-table>
            </Box>
            <Box width={mobile ? "85vw" : "300px"}  py={mobile ? 2 : 4}>
              <stripe-pricing-table pricing-table-id={pt2}
              publishable-key={publishable_key}
              client-reference-id={clientReferenceId}>
              </stripe-pricing-table>
            </Box>
          </Stack>
          
        </Box>
        <Stack sx={{backgroundImage: `url(https://i.imgur.com/kZjP0Jh.jpg)`}} pt={16} pb={20} alignItems="center" justifyContent="center" ref={ref5}>
          <Typography variant={mobile ? "h4" : "h2"} fontWeight="bold" textAlign="center">FAQs</Typography>
          <Box width="50vw" mt={6}>
            <Faq
                  data={data}
                  styles={styles}
                  config={config}
            />
          </Box>
        </Stack>
        <Stack sx={{backgroundImage: `url(https://i.imgur.com/2r04uzk.jpg)`}} pt={16} pb={tablet ? 26 : 18} px={2} alignItems="center" justifyContent="center" ref={ref6}>
          <Typography variant={mobile ? "h4" : "h2"} fontWeight="bold" textAlign="center" color="white" mb={4}>
            Explore&nbsp;
            <span style={{fontFamily: "Caveat", fontSize: mobile ? 48 : 64, fontWeight: "bold"}}>innov<span style={{color: "red"}}>AI</span>te</span>
          </Typography>

          <Typography color="white "fontSize={mobile ? 18 : 24} textAlign="center" mb={6}>
            Get a guided tour through <span style={{fontFamily: "Caveat", fontSize: 28, fontWeight: "bold"}}>innov<span style={{color: "red"}}>AI</span>te</span>, and find out how you and your team <br/>can change the way you innovate, forever.
          </Typography>
          <Box width="50%">
            <Button variant="outlined" fullWidth={true} onClick={() => scrollToRef(ref4)}
            sx={{fontSize: mobile ? 20 : 30, textTransform: "none", color: "white", border: "2px solid white", fontWeight: "bold", px: mobile ? 4 : 7, py: mobile ? 2 : 4, borderRadius: 5, transition: 'transform 0.4s', 
            '&:hover': {
              transform: 'scale(1.07)', // Adjust the scale factor as desired
              border: "2px solid white",
              color: "black",
              bgcolor: "white",
            } 
            }}>
              Get Started
            </Button>
          </Box>
          { mobile ? 
          <Stack spacing={5} alignItems="center">
            <Stack direction="row" alignItems="center" mt={12} spacing={4}>
            {imageSources.map((image, index) => (
              (index < 2 && <img src={image} alt={`logo ${index}`} width="auto" height={32} />)
            ))}
            </Stack>

            <Stack direction="row" alignItems="center" mt={8} spacing={4}>
            {imageSources.map((image, index) => (
              (index >= 2 && index <= 3 && <img src={image} alt={`logo ${index}`} width="auto" height={28} />)
            ))}
            </Stack>

            <Stack direction="row" alignItems="center" mt={8} spacing={4}>
            {imageSources.map((image, index) => (
              (index > 3 && <img src={image} alt={`logo ${index}`} width="auto" height={32} />)
            ))}
            </Stack>
          </Stack>

          : ( tablet ? 
            <Stack spacing={5} alignItems="center">
            <Stack direction="row" alignItems="center" mt={12} spacing={10}>
            {imageSources.map((image, index) => (
              (index < 3 && <img src={image} alt={`logo ${index}`} width="auto" height={40} />)
            ))}
            </Stack>

            <Stack direction="row" alignItems="center" mt={12} spacing={10}>
            {imageSources.map((image, index) => (
              (index > 2 && <img src={image} alt={`logo ${index}`} width="auto" height={40} />)
            ))}
            </Stack>
          </Stack>
          : <Stack direction="row" alignItems="center" mt={12} spacing={10}>
            {/*map each image source */}
            {imageSources.map((image, index) => (
              <img src={image} alt={`logo ${index}`} width="auto" height={40} />
            ))}
          </Stack>) }

          <Stack direction={mobile ? "column" : "row"} spacing={mobile ? 4 : 2} alignItems="flex-start" mt={15} width={mobile ? "90%" : "75%"}>
            <Stack direction="row" alignItems="center" spacing={2} width="100%" justifyContent={mobile ? "center" : "left"}>
              <Typography color="white" variant="h4" fontFamily="Caveat" fontWeight="bold" mr={2} onClick={() => scrollToRef(ref1)}
              sx={{":hover": {cursor: "pointer"}}}>
                innov<span style={{color: "red"}}>AI</span>te
              </Typography>
              <img src={`${process.env.PUBLIC_URL}/miami.webp`} alt="miami" width="auto" height={40} />
              <Typography fontSize={mobile ? 12 : 14} color="white">
                Headquartered in<br/>Tampa, Florida.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={6} width="100%" justifyContent={mobile ? "center" : "right"}>
              <Stack direction="column" spacing={1}>
                <Typography component={Link} href="https://alveiz.github.io/innovaite_portfolio/" target="_blank" fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Latest projects
                </Typography>
                <Typography component={Link} onClick={() => scrollToRef(ref4)} sx={{":hover": {cursor: "pointer"}}} fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Pricing
                </Typography>
                <Typography component={Link} href="https://forms.gle/vAoFK1LyMbvWyZV68" target="_blank" fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Upload Scope Doc
                </Typography>
                <Typography component={Link} href="mailto:hello@innovaite.tech" fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Contact
                </Typography>
                <Typography component={Link} href="https://innovaite.trackdesk.com/sign-up" target="_blank" fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Affiliate Link
                </Typography>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography component={Link} fontSize={mobile ? 14 : 16} color="white" fontWeight={500} href="https://billing.stripe.com/p/login/5kAg0A6fh86NbjG288" target="_blank">
                  Client login
                </Typography>
                <Typography component={Link} onClick={() => scrollToRef(ref1)} sx={{":hover": {cursor: "pointer"}}} fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Get started
                </Typography>
                <Typography component={Link} href="https://alveiz.github.io/innovaite_terms/" target="_blank" fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Terms of service
                </Typography>
                <Typography component={Link} href="https://alveiz.github.io/innovaite_privacy_policy/" target="_blank" fontSize={mobile ? 14 : 16} color="white" fontWeight={500}>
                  Privacy policy
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          { mobile ? <AppBar position="fixed">
          <Toolbar sx={{bgcolor: "#fff"}}>
            <Box>
              <Typography variant="h5" ml={"29vw"} color="#000" fontWeight="bold" fontFamily="Caveat" sx={{":hover": {cursor: "pointer"}}} onClick={()=>scrollToRef(ref1)}>
                innov<span style={{color: "red"}}>AI</span>te
              </Typography>
            </Box>
            <DrawerComponent/>
          </Toolbar></AppBar> : 
          <AppBar position="fixed" sx={{ top: 'auto', bottom: tablet ? "8vh" : "3vh", background: "transparent", boxShadow: 0}}>
              <Toolbar>
                <Box width="100%" display="flex" justifyContent="center">
                  <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} bgcolor="black" border={"1px solid #43464B"} py={1} px={1.5} borderRadius={15}>
                    <Box bgcolor="#43464B" py={2} px={3} borderRadius={10} display="flex" justifyContent="center"onClick={() => scrollToRef(ref1)}
                    sx={{transition: 'background-color 0.3s ease, color 0.3s ease', ":hover": {bgcolor: "gray", cursor: "pointer"}}}>
                      <KeyboardDoubleArrowUpRoundedIcon sx={{color: "white", fontSize: 30}}/>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" onClick={() => scrollToRef(ref2)}
                    sx={{ 
                      borderRadius: isActive('section2') ? 10 : 1,
                      bgcolor: isActive('section2') ? 'white' : 'transparent',
                      color: isActive('section2') ? '#000' : 'inherit',
                      transition: 'background-color 0.6s ease, color 0.6s ease, border-radius 0.6s ease',
                      ":hover": {bgcolor: "white", color: "#000", borderRadius: 10, cursor: "pointer"
                      }}} >
                      <Typography fontSize={mobile ? 14 : 18} color="inherit" fontWeight="bold">
                        Benefits
                      </Typography>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" onClick={() => scrollToRef(ref3)}
                    sx={{  
                      borderRadius: isActive('section3') ? 10 : 1,
                      bgcolor: isActive('section3') ? 'white' : 'black',
                      color: isActive('section3') ? '#000' : 'inherit', 
                      transition: 'background-color 0.6s ease, color 0.6s ease, border-radius 0.6s ease',
                      ":hover": {bgcolor: "white", color: "#000", borderRadius: 10, cursor: "pointer"
                      }}} >
                      <Typography fontSize={mobile ? 14 : 18} color="inherit" fontWeight="bold" >
                        Recent work
                      </Typography>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" onClick={() => scrollToRef(ref4)}
                    sx={{ 
                      borderRadius: isActive('section4') ? 10 : 1,
                      bgcolor: isActive('section4') ? 'white' : 'transparent',
                      color: isActive('section4') ? '#000' : 'inherit',
                      transition: 'background-color 0.6s ease, color 0.6s ease, border-radius 0.6s ease',
                      ":hover": {bgcolor: "white", color: "#000", borderRadius: 10, cursor: "pointer"
                      }}} >
                      <Typography fontSize={mobile ? 14 : 18} color="inherit" fontWeight="bold">
                        Pricing
                      </Typography>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" onClick={() => scrollToRef(ref5)}
                    sx={{ 
                    borderRadius: isActive('section5') ? 10 : 1,
                    bgcolor: isActive('section5') ? 'white' : 'transparent',
                    color: isActive('section5') ? '#000' : 'inherit',
                    transition: 'background-color 0.6s ease, color 0.6s ease, border-radius 0.6s ease',
                      ":hover": {bgcolor: "white", color: "#000", borderRadius: 10, cursor: "pointer"
                      }}} >
                      <Typography fontSize={mobile ? 14 : 18} color="inherit" fontWeight="bold">
                        FAQs
                      </Typography>
                    </Box>
                    <Box p={2} display="flex" justifyContent="center" onClick={()=> window.open("https://forms.gle/vAoFK1LyMbvWyZV68")} target="_blank"
                    sx={{ borderRadius: 1, transition: 'background-color 0.6s ease, color 0.6s ease, border-radius 0.6s ease',
                      ":hover": {bgcolor: "white", color: "#000", borderRadius: 10, cursor: "pointer"
                      }}} >
                      <Typography fontSize={mobile ? 14 : 18} color="inherit" fontWeight="bold">
                        Upload Scope Doc
                      </Typography>
                    </Box>
                    <Box bgcolor="#43464B" p={2} borderRadius={10} display="flex" justifyContent="center" 
                    sx={{transition: 'background-color 0.5s ease, color 0.5s ease', ":hover": {bgcolor: "gray", cursor: "pointer"}}}>
                      <Typography fontSize={mobile ? 14 : 18} color="white" fontWeight="bold" component={Link} sx={{textDecoration: "none"}} href="https://billing.stripe.com/p/login/5kAg0A6fh86NbjG288" target="_blank">
                        Login
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Toolbar>
            </AppBar>
            }
        </Stack>
        </Box>
      </ThemeProvider>
  );
}

export default App;
