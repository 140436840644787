export const colorTokens = {
    grey: {
      0: "#FFFFFF",
      10: "#fdfbf5",
      50: "#fcfcfc",
      100: "#fbf9f0",
      200: "#fbf9f1",
      300: "#fafafa",
      400: "#fbf9f3",
      500: "#fff",
      600: "#f4f2eb",
      700: "#edeae2",
      800: "#e6e2da",
      900: "#d0ccc3",
      1000: "#000000",
    },
    primary: {
      50: "#9491e3",
      100: "#7c7bdf",
      200: "#6d6fdd",
      300: "#5d5ea3",
      400: "#4b53d6",
      500: "#000",
      600: "#313abc",
      700: "#292a6e",
      800: "#2a2b98",
      900: "#282689",
    },
  };
  
  export const themeSettings = (mode) => {
    return {
      palette: {
        primary: {
          dark: colorTokens.primary[700],
          main: colorTokens.primary[500],
          light: colorTokens.primary[300],//50
        },
        neutral: {
          dark: colorTokens.grey[700],
          main: colorTokens.grey[500],
          mediumMain: colorTokens.grey[400],
          medium: colorTokens.grey[300],
          light: colorTokens.grey[100],
        },
        background: {
          default: colorTokens.grey[500],
          alt: colorTokens.grey[50],
        },
            
      },
      typography: {
        fontSize: 12,
        fontFamily: ["Caveat", "Mulish", ],
        h1: {
          fontSize: 70,
        },
        h2: {
          fontSize: 60,
        },
        h3: {
          fontSize: 50,
        },
        h4: {
          fontSize: 40,
        },
        h5: {
          fontSize: 30,
        },
        h6: {
          fontSize: 24,
        },
      },
    };
  };